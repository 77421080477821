// indexedDBUtils.js
const DB_NAME = "chatDatabase";
const DB_VERSION = 1;
const STORE_NAME = "chats";

// Відкриття бази даних
export const openDB = () => {
	return new Promise((resolve, reject) => {
		const request = indexedDB.open(DB_NAME, DB_VERSION);

		request.onupgradeneeded = event => {
			const db = event.target.result;
			db.createObjectStore(STORE_NAME, { keyPath: "id", autoIncrement: true });
		};

		request.onsuccess = event => {
			resolve(event.target.result);
		};

		request.onerror = event => {
			reject(event.target.error);
		};
	});
};

// Отримання всіх чатів
export const getChats = async () => {
	const db = await openDB();
	return new Promise((resolve, reject) => {
		const transaction = db.transaction(STORE_NAME, "readonly");
		const store = transaction.objectStore(STORE_NAME);
		const request = store.getAll();

		request.onsuccess = () => {
			resolve(request.result);
		};

		request.onerror = () => {
			reject(request.error);
		};
	});
};

// Додавання або оновлення чатів
export const saveChats = async chats => {
	const db = await openDB();
	return new Promise((resolve, reject) => {
		const transaction = db.transaction(STORE_NAME, "readwrite");
		const store = transaction.objectStore(STORE_NAME);
		chats.forEach(chat => store.put(chat));

		transaction.oncomplete = () => {
			resolve();
		};

		transaction.onerror = () => {
			reject(transaction.error);
		};
	});
};
