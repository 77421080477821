/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import Chats from "./components/Chats";
import CurrentChat from "./components/CurrentChat";
import Settings from "./components/Settings";
import { getChats, saveChats } from "./indexedDB"; // Імпортуємо функції

function App() {
	const [chats, setChats] = useState([]);
	const [messages, setMessages] = useState([]);
	const [currentChat, setCurrentChat] = useState(null);
	const [sended, setSended] = useState(false);
	const [settings, setSettings] = useState({
		amount: 0,
		is_reg: false,
		is_cpa: false,
	});
	const [number, setNumber] = useState(0);
	const [newMessageQueue, setNewMessageQueue] = useState([]);
	const [socket, setSocket] = useState(null);

	const generateUniqueId = existingIds => {
		let newId;
		do {
			newId = uuidv4();
		} while (existingIds.includes(newId));
		return newId;
	};

	const isURL = str => {
		const pattern = new RegExp(
			"^(https?:\\/\\/)?" + // протокол
				"((([a-zA-Z\\d]([a-zA-Z\\d-]*[a-zA-Z\\d])*)\\.)+[a-zA-Z]{2,}|" + // доменне ім'я
				"((\\d{1,3}\\.){3}\\d{1,3}))" + // або IP (v4) адреса
				"(\\:\\d+)?(\\/[-a-zA-Z\\d%@_.~+&:]*)*" + // порт і шлях
				"(\\?[;&a-zA-Z\\d%@_.,~+&:=-]*)?" + // запит
				"(\\#[-a-zA-Z\\d_]*)?$",
			"i",
		); // фрагмент

		return !!pattern.test(str);
	};

	useEffect(() => {
		if (newMessageQueue.length === 0) return;
		const findChatIndex = chats.findIndex(item => item.id === currentChat?.id);
		const currentMessages = chats[findChatIndex]?.messages;

		if (!isURL(newMessageQueue[0])) {
			const messIds = currentMessages.map(mess => mess.id);
			const messId = generateUniqueId(messIds);
			currentMessages.push({ id: messId, message: newMessageQueue[0], type: "text", ai: true });
			// const messages = JSON.parse(newMessageQueue[0].text);
			// messages.messages.forEach(item => {
			// 	const messIds = currentMessages.map(mess => mess.id);
			// 	const messId = generateUniqueId(messIds);
			// 	currentMessages.push({ id: messId, message: item.text, ai: true });
			// });
		} else {
			const messIds = currentMessages.map(mess => mess.id);
			const messId = generateUniqueId(messIds);
			currentMessages.push({ id: messId, message: newMessageQueue[0], type: "media", ai: true });
		}

		setMessages([...currentMessages]);
		const newChat = { messages: currentMessages, id: currentChat.id, ...settings };
		setCurrentChat(newChat);

		const newChats = [...chats];

		newChats[findChatIndex].messages = currentMessages;
		setChats(newChats);
		setSended(false);
		setNewMessageQueue(prevQueue => prevQueue.slice(1));

		return () => false;
	}, [newMessageQueue]);

	useEffect(() => {
		setSettings({
			amount: +currentChat?.amount || 0,
			is_reg: currentChat?.is_reg || false,
			is_cpa: currentChat?.is_cpa || false,
		});
		setNumber(+currentChat?.amount || 0);
	}, [currentChat]);

	useEffect(() => {
		// Отримання чатів з IndexedDB
		const fetchChats = async () => {
			try {
				const storedChats = await getChats();
				setChats(storedChats);
			} catch (error) {
				console.error("Error fetching chats:", error);
			}
		};

		fetchChats();
	}, []);

	useEffect(() => {
		// Збереження чатів в IndexedDB
		const updateChats = async () => {
			try {
				await saveChats(chats);
			} catch (error) {
				console.error("Error saving chats:", error);
			}
		};

		if (chats.length > 0) {
			updateChats();
		}
	}, [chats]);

	useEffect(() => {
		// const eventSource = new EventSource("https://ai.raulnk.com/events");

		// eventSource.onmessage = event => {
		// 	const newData = JSON.parse(event.data);
		// 	setNewMessageQueue(prevQueue => [...prevQueue, newData]);
		// };

		// eventSource.onerror = err => {
		// 	console.error(err);
		// };

		// return () => {
		// 	eventSource.close();
		// };
		if (!currentChat?.id || !currentChat) return; // Якщо chatId не визначено, нічого не робимо

		// Створюємо нове з'єднання з вебсокетом
		const ws = new WebSocket(`wss://ai.raulnk.com/ws/${currentChat.id}`);

		// Зберігаємо екземпляр WebSocket у стані
		setSocket(ws);

		// Обробка подій WebSocket
		ws.onopen = () => {
			console.log(`Підключено до WebSocket для чату ${currentChat.id}`);
		};

		ws.onmessage = event => {
			console.log("Отримано повідомлення:", event.data);
			setTimeout(() => {
				setNewMessageQueue(prevQueue => [...prevQueue, event.data]);
			}, 1000);
		};

		ws.onerror = error => {
			console.error("Помилка WebSocket:", error);
		};

		ws.onclose = () => {
			console.log(`WebSocket з'єднання закрите для чату ${currentChat.id}`);
		};

		// Очищення при розмонтуванні компонента або зміні chatId
		return () => {
			ws.close();
		};
	}, [currentChat]);

	return (
		<div className="uiContainer">
			<Chats
				chats={chats}
				setChats={setChats}
				currentChat={currentChat}
				setCurrentChat={setCurrentChat}
				sended={sended}
				setSended={setSended}
				setSettings={setSettings}
				setNumber={setNumber}
			/>
			<CurrentChat
				chats={chats}
				setChats={setChats}
				messages={messages}
				setMessages={setMessages}
				currentChat={currentChat}
				setCurrentChat={setCurrentChat}
				sended={sended}
				setSended={setSended}
				settings={settings}
				number={number}
			/>
			<Settings
				settings={settings}
				setSettings={setSettings}
				number={number}
				setNumber={setNumber}
			/>
		</div>
	);
}

export default App;
