import React, { useEffect, useState } from "react";

const Settings = ({ settings, setSettings, number, setNumber, setSended }) => {
	return (
		<section className="uiSettings">
			<label
				htmlFor=""
				className="field"
			>
				<span>Deposit amount:</span>
				<input
					style={number > settings.amount ? { border: "2px solid red" } : {}}
					type="text"
					name=""
					id=""
					value={String(settings.amount)}
					onChange={e => {
						setSettings({
							...settings,
							amount: Number(e.target.value.replace(/[^0-9]/g, "")),
						});
					}}
					onBlur={() => {
						if (number > settings.amount) {
							setSettings({ ...settings, amount: Number(number) });
						}
					}}
				/>
			</label>
			<label
				htmlFor=""
				className="field2"
			>
				<span>Registration:</span>
				<input
					type="checkbox"
					name=""
					id=""
					disabled={!!settings.is_reg ? true : false}
					checked={settings.is_reg}
					onChange={e => {
						setSettings({ ...settings, is_reg: e.target.checked });
					}}
				/>
			</label>
			<label
				htmlFor=""
				className="field2"
			>
				<span>CPA:</span>
				<input
					type="checkbox"
					name=""
					id=""
					disabled={!!settings.is_cpa ? true : false}
					checked={settings.is_cpa}
					onChange={e => {
						setSettings({ ...settings, is_cpa: e.target.checked });
					}}
				/>
			</label>
		</section>
	);
};

export default Settings;
