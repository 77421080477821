/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";

const CurrentChat = ({
	currentChat,
	setCurrentChat,
	messages,
	setMessages,
	chats,
	setChats,
	sended,
	setSended,
	settings,
	number,
}) => {
	// const [messages, setMessages] = useState([]);
	const [message, setMessage] = useState("");

	useEffect(() => {
		if (!!currentChat) {
			setMessages(currentChat.messages);
			setMessage("");
		}
	}, [currentChat]);

	useEffect(() => {
		if (messages.length !== 0) {
			const messagesList = document.getElementById("messagesList");
			if (messagesList) {
				messagesList.scrollTop = messagesList.scrollHeight;
			}
		}
	}, [messages, message]);

	const handleChange = e => {
		setMessage(e.target.value);
	};

	const generateUniqueId = existingIds => {
		let newId;
		do {
			newId = uuidv4();
		} while (existingIds.includes(newId));
		return newId;
	};

	const generateUniqueIdForChat = existingIds => {
		let uniqueId;

		// Генеруємо ID, поки не отримаємо унікальний
		do {
			uniqueId = Math.floor(1000 + Math.random() * 9999999999);
		} while (existingIds.includes(uniqueId));

		return uniqueId;
	};

	const sendMessage = async (chat_id, message, chatsProp, messagesProp, settings) => {
		fetch(`https://ai.raulnk.com/rpc/chat`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				text: message,
				chat_id,
				lead_id: chat_id,
				is_test: true,
				...settings,
			}),
		})
			.then(res => {
				if (!res.ok) {
					throw new Error("Network response was not ok");
				}
				return res.json();
			})
			.then(response => {
				// console.log(response);
				// const newMessages = JSON.parse(response.message);
				// const messagesArr = [...messagesProp];
				// newMessages.messages.forEach((item, index) => {
				// 	const messIds = messagesArr.map(mess => mess.id);
				// 	const messId = generateUniqueId(messIds);
				// 	messagesArr.push({ id: messId, message: item.text, ai: true });
				// });
				// setMessages(messagesArr);
				// const newChat = { messages: messagesArr, id: chat_id, ...settings };
				// setCurrentChat(newChat);
				// const newChats = [...chatsProp];
				// let indexChat = newChats.findIndex(item => item.id === chat_id);
				// newChats[indexChat].messages = messagesArr;
				// setChats(newChats);
				// setSended(false);
			})
			.catch(error => {
				console.error("There has been a problem with your fetch operation:", error);
			});
	};

	const send = async () => {
		if (!message.length || !!sended || number > settings.amount) return;
		setSended(true);
		const newMessages = [...messages];
		const messIds = newMessages.map(mess => mess.id);
		const messId = generateUniqueId(messIds);
		newMessages.push({ id: messId, message: message });
		setMessage("");
		setMessages(newMessages);

		if (!currentChat) {
			const existingIds = chats.map(chat => chat.id);
			const newId = generateUniqueIdForChat(existingIds);
			const newChat = {
				messages: newMessages,
				id: newId,
				lead_id: newId,
				is_test: true,
				date: new Date().getTime(),
				...settings,
			};
			setCurrentChat(newChat);
			setChats([newChat, ...chats]);
			sendMessage(newId, message, [newChat, ...chats], newMessages, settings);
			return;
		}

		const newChat = {
			messages: newMessages,
			id: currentChat.id,
			lead_id: currentChat.id,
			is_test: true,
			...settings,
		};
		setCurrentChat(newChat);

		const newChats = [...chats];
		let indexChat = newChats.findIndex(item => item.id === currentChat.id);

		newChats[indexChat].messages = newMessages;
		newChats[indexChat] = { ...newChats[indexChat], ...settings };
		setChats(newChats);
		sendMessage(currentChat.id, message, newChats, newMessages, settings);
	};

	return (
		<section className="uiCurrentChat">
			<div className="container">
				<div
					className="messagesList"
					id="messagesList"
				>
					{messages.map(mes => {
						return (
							<div
								className="messagesItem"
								key={mes.id}
							>
								<div className="id">{!!mes.ai ? "AI" : `YOU`}</div>
								{mes.message}
								{(mes.message.includes(".png") || mes.message.includes(".jpg")) && (
									<img
										src={mes?.message}
										alt=""
										className="image"
									/>
								)}
								{mes.message.includes("mp4") && (
									<video
										controls
										className="image"
									>
										<source
											src={mes?.message}
											type="video/mp4"
										/>
										Your browser does not support the video tag.
									</video>
								)}
							</div>
						);
					})}
					{sended && (
						<div className="messagesItem">
							<div className="plug">
								<span className="loader"></span>
							</div>
						</div>
					)}
				</div>
				<div className="inputContainer">
					<input
						type="text"
						onChange={handleChange}
						value={message}
						onKeyDown={e => {
							if (e.key === "Enter") {
								send();
							}
						}}
					></input>
					<button onClick={send}>Send</button>
				</div>
			</div>
		</section>
	);
};

export default CurrentChat;
