import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";

const Chats = ({
	chats,
	setChats,
	currentChat,
	setCurrentChat,
	sended,
	setSended,
	setSettings,
	setNumber,
}) => {
	const generateUniqueId = existingIds => {
		let newId;
		do {
			newId = uuidv4();
		} while (existingIds.includes(newId));
		return newId;
	};

	const generateUniqueIdForChat = existingIds => {
		let uniqueId;

		// Генеруємо ID, поки не отримаємо унікальний
		do {
			uniqueId = Math.floor(1000 + Math.random() * 9999999999);
		} while (existingIds.includes(uniqueId));

		return uniqueId;
	};

	const createChat = async () => {
		if (!!sended) return;
		const existingIds = chats.map(chat => chat.id);
		const newId = generateUniqueIdForChat(existingIds);
		const newChat = {
			messages: [],
			id: newId,
			lead_id: newId,
			is_test: true,
			date: new Date().getTime(),
			amount: "",
			is_reg: false,
			is_cpa: false,
		};
		setCurrentChat(newChat);
		setChats([newChat, ...chats]);
		setSettings({ amount: 0, is_reg: false, is_cpa: false });
		setNumber(0);
	};

	return (
		<nav className="uiChats">
			<div className="newChat">
				<button onClick={createChat}>New Chat +</button>
			</div>
			<ul className="list">
				{chats
					.sort((a, b) => b.date - a.date)
					.map(item => {
						return (
							<li
								key={item.id}
								onClick={() => {
									setCurrentChat(item);
									setSettings({
										amount: item?.amount,
										is_reg: item?.is_reg,
										is_cpa: item?.is_cpa,
									});
									setNumber(item?.amount);
								}}
								className={`item ${currentChat?.id === item?.id ? "_active" : ""}`}
							>
								{item?.messages[0]?.message}
							</li>
						);
					})}
			</ul>
		</nav>
	);
};

export default Chats;
